
/* Popup */
.popup-layer {
    position: absolute;
    z-index: 999;
    background-color: #fff;
    &__inner {
        height: 100%;
    }
    &__body {
        display: block;
        width: 100% !important;
        height: 100% !important;
        border-radius: 0 !important;
    }
    &__content {
        height: 100%;
        line-height: 0;
    }
    &__foot {
        padding: 6px 12px;
        background-color: var(--v-grey-darken3);
    }
}
@media (max-width: 576px) {
    .popup-layer {
        max-width: calc(100vw - var(--container-gutter) * 2);
        height: auto !important;
        top: var(--page-section-padding-y) !important;
        left: var(--container-gutter) !important;
    }
}
